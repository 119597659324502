import {type UserModule} from '~/types'

export const install: UserModule = ({isClient, router}) => {
  if (isClient) {
    const authStore = useAuthStore()
    router.beforeEach(async (to) => {
      if (!to.meta.guest && !authStore.checkedAuthenticate) {
        try {
          await authStore.getMe()
        }
        catch (error) {
          return {name: 'login'}
        }
      }

      if (!to.meta.guest) {
        if (!authStore.isAuthenticated) { return {name: 'login'} }
        else {
          if (authStore.user?.emailVerifiedAt == null && to.name !== 'register-verify')
            return {name: 'register-verify'}
          else if (authStore.user?.emailVerifiedAt != null && authStore.user.customer == null && to.name !== 'register-details')
            return ({name: 'register-details'})
        }
      }
    })
  }
}
