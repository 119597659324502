import axiosInstance from '~/api/axios'
import type {Institution} from '~/types/institution.type'

// TODO maybe use useAxios instead
class InstitutionsModule {
  getInstitutions(code: string) {
    return axiosInstance.get<Institution>('/institution/', {params: {code}})
  }
}

const module = new InstitutionsModule()
export default module
