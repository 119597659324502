<script setup lang="ts">
// https://github.com/vueuse/head
// you can use this to manipulate the document head in any components,
// they will be rendered correctly in the html results with vite-ssg
// useHead({
//   title: 'Vitesse',
//   meta: [
//     {name: 'description', content: 'Opinionated Vite Starter Template'},
//     {
//       name: 'theme-color',
//       content: computed(() => isDark.value ? '#00aba9' : '#ffffff'),
//     },
//   ],
//   link: [
//     {
//       rel: 'icon',
//       type: 'image/svg+xml',
//       href: computed(() => preferredDark.value ? '/favicon-dark.svg' : '/favicon.svg'),
//     },
//   ],
// })

const authStore = useAuthStore()
const router = useRouter()
const route = useRoute()
const customerNotificationsStore = useCustomerNotificationStore()
onMounted(async () => {
  try {
    await customerNotificationsStore.getCustomerNotifications(authStore.user?.customer?.id)
  }

  catch (error) {
    if (!route.meta.guest)
      await router.push({name: 'login'})
  }
})

</script>

<template>
  <NotificationBar />
  <RouterView />
</template>
