import {format} from 'date-fns'
import axiosInstance from '../axios'
import {ApiModule} from '../ApiModule'
import type {MenuLine, PermanentMenuLine} from '~/types/menuLine.type'

class MenuLineModule extends ApiModule {
  async getMenuLines(diningParticipantId: number, startDate: Date, endDate: Date) {
    this.cancelPreviousRequest(diningParticipantId.toString())
    return axiosInstance.get<MenuLine[]>(`/dining-participant/${diningParticipantId}/menu-lines/`, {params: {startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd')}, signal: this.getAbortController(diningParticipantId.toString())?.signal})
  }

  fetchPermanentMenuLines(diningParticipantId: number) {
    this.cancelPreviousRequest(diningParticipantId.toString())
    return axiosInstance.get<PermanentMenuLine[]>(`/dining-participant/${diningParticipantId}/menu-lines/permanent`, {signal: this.getAbortController(diningParticipantId.toString())?.signal})
  }

  fetchMenuLines(customerId: number, startDate: Date, endDate: Date) {
    this.cancelPreviousRequest(customerId.toString())

    return axiosInstance.get(`customer/${customerId}/menu-lines`, {params: {startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd')}, signal: this.getAbortController(customerId.toString())?.signal})
  }
}

const module = new MenuLineModule()
export default module
