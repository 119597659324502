import type {Customer} from './customer.type'

export interface IUser {
  id: number
  email: string
  emailVerifiedAt: Date
  firstName: string
  lastName: string
  role: string
  customer: Customer
}

export class User implements IUser {
  id!: number
  email!: string
  emailVerifiedAt!: Date
  firstName!: string
  lastName!: string
  role!: string
  customer!: Customer

  constructor(user: Partial<IUser> = {}) { Object.assign(this, user) }
}
