import {acceptHMRUpdate, defineStore} from 'pinia'
import api from '~/api'
import type {CustomerNotification} from '~/types/notification.type'

export const useCustomerNotificationStore = defineStore('customerNotification', () => {
  const notifications: Ref<Array<CustomerNotification>> = ref([])

  async function getCustomerNotifications(customerId: number) {
    try {
      const res = await api.customerNotifications.getCustomerNotifications(customerId)
      notifications.value = res.data
    }
    catch (e) {
    }
  }
  return {
    getCustomerNotifications,
    notifications,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useCustomerNotificationStore, import.meta.hot))
