<template>
  <div
    class="text-[#00558C] text-4xl flex"
    :data-cy="cyMenuFullscreenSelector"
  >
    <div
      v-if="!value"
      class="justify-end py-3 px-2 text-4xl text-[#00558C] w-full"
      :data-cy="cyMenuFullscreenIconSelector"
    >
      <slot name="trigger">
        <span
          :class="['cursor-pointer', value ? 'i-tabler-x' : 'i-tabler-menu-2', 'block z-98']"
          @click="value = !value"
        />
      </slot>
    </div>

    <Teleport to="body">
      <Transition name="slide">
        <div
          v-if="value"
          class="fixed top-0 left-0 h-screen w-screen bg-yellow-500 overflow-auto z-100"
        >
          <div class="flex justify-end py-3 px-2 text-4xl text-[#00558C]">
            <div class="pointer-events-auto">
              <img
                src="/logo-y.png"
                class="absolute top-0 left-0 h-[6rem] md:h-[8rem] transition-all z-100"
                :data-cy="cyMenuFullscreenImgSelector"
                @click="() => {router.push({name: 'dashboard'}); value = !value}"
              >
            </div>
            <span
              :data-cy="cyMenuFullscreenIconSelector"
              :class="['cursor-pointer', value ? 'i-tabler-x' : 'i-tabler-menu-2', 'block']"
              @click="value = !value"
            />
          </div>
          <div class="mt-[2rem]">
            <slot name="content" />
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import {
  cyMenuFullscreenIconSelector,
  cyMenuFullscreenImgSelector,
  cyMenuFullscreenSelector,
  menuFullscreenProps,
} from './interface'

const props = defineProps(menuFullscreenProps)
const emits = defineEmits(["update:modelValue"])
const router = useRouter()
const uncontrolledValue = ref(false)
let isLocked = useScrollLock(null)

onMounted(() => {
  isLocked = useScrollLock(document.body)
})
const value = computed({
  get() {
    if (props.modelValue === undefined)
      return uncontrolledValue.value
    else
      return props.modelValue
  },
  set(value) {
    uncontrolledValue.value = value
    isLocked.value = value
    emits('update:modelValue', value)
  },
})

watch(() => props.modelValue, (newValue) => {
  if (newValue !== undefined)
    value.value = newValue
})

</script>
