<template>
  <div
    :class="['shadow-md flex flex-col p-3 rounded-md', type]"
    :data-cy="cyNotificationSelector"
  >
    <div class="flex justify-between items-center">
      <div class="text-xs title">
        <slot name="title">
          {{ props.title }}
        </slot>
      </div>
      <div class="flex justify-center items-center">
        <slot name="header-extra" />
        <div
          v-if="props.closable"
          class="text-xl"
        >
          <slot name="closable-icon">
            <div
              class="i-tabler-x"
              @click="handleClick"
            />
          </slot>
        </div>
      </div>
    </div>
    <div class="text-xl">
      <slot name="content">
        {{ props.content }}
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import {NOTIFICATION_TYPE, cyNotificationSelector, notificationProps} from './interface'
const props = defineProps(notificationProps)
const emits = defineEmits(["close"])

const type = computed(() => {
  switch (props.type) {
    case NOTIFICATION_TYPE.DEFAULT:
      return props.styleType === 'default' ? 'default' : 'default-simple'
    case NOTIFICATION_TYPE.SUCCESS:
      return props.styleType === 'default' ? 'success' : 'success-simple'
    case NOTIFICATION_TYPE.WARNING:
      return props.styleType === 'default' ? 'warning' : 'warning-simple'
    case NOTIFICATION_TYPE.ERROR:
      return props.styleType === 'default' ? 'error' : 'error-simple'
    case NOTIFICATION_TYPE.INFO:
      return props.styleType === 'default' ? 'info' : 'info-simple'
    case NOTIFICATION_TYPE.SYSTEM:
      return props.styleType === 'default' ? 'system' : 'system-simple'
  }
})
function handleClick() {
  emits('close')
}
</script>

<style scoped>

.error {
  @apply bg-red-100 text-red-500 bg-opacity-90;
}

.success {
  @apply bg-green-100 text-green-500 bg-opacity-90;
}

.warning {
  @apply bg-yellow-100 text-yellow-500 bg-opacity-90;
}

.info {
  @apply bg-blue-100 text-blue-500 bg-opacity-90;
}

.default {
  @apply bg-white text-gray-5 bg-opacity-90;
}

.error-simple {
  @apply bg-black text-red-5 bg-opacity-40;
}

.success-simple {
  @apply bg-green-100 text-green-500 bg-opacity-80;
}

.warning-simple {
  @apply bg-yellow text-yellow-5 bg-opacity-80;
}

.info-simple {
  @apply bg-gray-500 text-black bg-opacity-40;
}

.default-simple {
  @apply bg-gray-500 text-black bg-opacity-40;
}
.system-simple {
  @apply bg-gray-500 text-blac bg-opacity-40;
}

.default-simple .title {
  @apply  text-black;
}

.info-simple .title {
  @apply  text-blue-500;
}

.error-simple .title {
  @apply  text-red-500;
}
.system-simple .title {
  @apply  text-red-500;
}
.warning-simple .title {
  @apply  text-red-500;
}
.success-simple .title {
  @apply  text-green-500;
}
</style>
