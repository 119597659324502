import applyCaseMiddleware from 'axios-case-converter'
import axios from 'axios'
import {handleResponseDates} from '~/utils/parser'
const axiosInstance
  // is always true, but necessary because of a bug while building with vite-ssg
  = (typeof applyCaseMiddleware === 'function')
    ? applyCaseMiddleware(
      axios.create({
        withCredentials: true,
        timeout: 28000,
        baseURL: import.meta.env.VITE_APP_API_BASE_URL,
      }),
      {},
    )
    : axios.create({},
    )

axiosInstance.interceptors.response.use((response) => {
  handleResponseDates(response.data)
  return response
})

export function setLanguage(lang: string) {
  axiosInstance.defaults.headers.common['X-localization'] = lang
}

export default axiosInstance

