import type {ButtonHTMLAttributes, ExtractPropTypes, PropType} from 'vue-demi'

export const BASE_BUTTON_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
}

export type BaseButtonSize = typeof BASE_BUTTON_SIZE[keyof typeof BASE_BUTTON_SIZE]

export const BASE_BUTTON_TYPE = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
}

export type BaseButtonType = typeof BASE_BUTTON_TYPE[keyof typeof BASE_BUTTON_TYPE]

export const ICON_PLACEMENT = {
  LEFT: 'left',
  RIGHT: 'right',
}

export type IconPlacement = typeof ICON_PLACEMENT[keyof typeof ICON_PLACEMENT]

export const baseButtonProps = {
  disabled: {
    type: Boolean,
    default: false,
  },
  iconPlacement: {
    type: String as PropType<IconPlacement>,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String as PropType<BaseButtonSize>,
    default: BASE_BUTTON_SIZE.MEDIUM,
  },
  type: {
    type: String as PropType<BaseButtonType>,
    default: BASE_BUTTON_TYPE.DEFAULT,
  },
  primary: {
    type: Boolean,
    default: true,
  },
  animation: {
    type: Boolean,
    default: true,
  },
  buttonProps: {
    type: Object as PropType<Omit<ButtonHTMLAttributes, 'disabled' | 'name' | 'type'>>,
  },
}

export type BaseButtonProps = ExtractPropTypes<typeof baseButtonProps>
export const cyBaseButton = 'baseButton'
export const cyBaseButtonIconLeft = 'baseButtonIconLeft'
export const cyBaseButtonIconRight = 'baseButtonIconRight'
