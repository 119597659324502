<template>
  <button
    v-bind="props.buttonProps"
    ref="buttonRef"
    :disabled="props.disabled || props.loading"
    :data-cy="cyBaseButton"
    :class="[typeClass, btnSizeClass, props.animation ? 'animation' : '', 'btn']"
    @click.prevent
  >
    <div
      v-if="showIconLeft"
      :class="[iconSizeClass, 'pr-4']"
      :data-cy="cyBaseButtonIconLeft"
    >
      <slot
        name="icon"
      />
    </div>
    <div
      v-if="props.loading"
      class="i-svg-spinners:180-ring-with-bg"
    />
    <div
      v-else
      class="text-center w-full"
    >
      <slot
        name="default"
      />
    </div>

    <div
      v-if="showIconRight"
      :class="[iconSizeClass, 'pl-4']"
      :data-cy="cyBaseButtonIconRight"
    >
      <slot
        name="icon"
      />
    </div>
  </button>
</template>

<script setup lang="ts">
import {
  BASE_BUTTON_SIZE,
  BASE_BUTTON_TYPE,
  ICON_PLACEMENT,
  baseButtonProps,
  cyBaseButton,
  cyBaseButtonIconLeft,
  cyBaseButtonIconRight,
} from './interface'

const props = defineProps(baseButtonProps)

const buttonRef = ref<HTMLButtonElement | null>(null)

const showIconLeft = ref(props.iconPlacement === ICON_PLACEMENT.LEFT)
const showIconRight = ref(props.iconPlacement === ICON_PLACEMENT.RIGHT)

const btnSizeClass = computed<string>(() => {
  switch (props.size) {
    case BASE_BUTTON_SIZE.SMALL:
      return 'size-small'
    case BASE_BUTTON_SIZE.LARGE:
      return 'size-large'
    default:
      return 'size-medium'
  }
})

const typeClass = computed<string>(() => {
  switch (props.type) {
    case BASE_BUTTON_TYPE.SUCCESS:
      return props.primary ? 'type-success primary' : 'type-success secondary'
    case BASE_BUTTON_TYPE.WARNING:
      return props.primary ? 'type-warning primary' : 'type-warning secondary'
    case BASE_BUTTON_TYPE.ERROR:
      return props.primary ? 'type-error primary' : 'type-error secondary'
    default:
      return props.primary ? 'type-default primary' : 'type-default secondary'
  }
})

const iconSizeClass = computed<string>(() => {
  switch (props.size) {
    case BASE_BUTTON_SIZE.SMALL:
      return 'icon-small'
    case BASE_BUTTON_SIZE.LARGE:
      return 'icon-large'
    default:
      return 'icon-medium'
  }
})

</script>

<style lang="scss">
.btn {
  @apply py-1 text-white cursor-pointer rounded flex items-center place-content-center
  enabled:focus:outline enabled:focus:outline-1 enabled:focus:outline-offset-2
}
.animation {
  @apply enabled:hover:rounded-3xl transition-all
}
.icon-small {
  @apply h-6
}
.icon-medium {
  @apply h-8
}
.icon-large {
  @apply h-10
}
.size-small {
  @apply h-8 px-2
}
.size-medium {
  @apply h-10 px-4
}
.size-large {
  @apply h-12 px-6
}

.type-default {
  &.primary {
    @apply text-white bg-blue-500
    enabled:active:bg-blue-800
    enabled:hover:bg-blue-400
    enabled:focus:outline-color-blue-500
    disabled:bg-gray-100 disabled:text-gray-200
  }
  &.secondary {
    @apply bg-white text-blue-500 border-2 border-blue-500
    enabled:active:text-blue-800 enabled:active:border-blue-800
    enabled:hover:text-blue-400
    enabled:hover:border-blue-400
    enabled:focus:outline-color-blue-500
    disabled:bg-gray-100 disabled:text-gray-200 disabled:border-gray-200
  }
}
.type-success {
  &.primary {
    @apply bg-green-500
    enabled:active:bg-green-800
    enabled:hover:bg-green-400
    enabled:focus:outline-color-green-500
    disabled:bg-gray-100 disabled:text-gray-200
  }
  &.secondary {
    @apply bg-white text-green-500 border-2 border-green-500
    enabled:active:text-green-800 enabled:active:border-green-800
    enabled:hover:text-green-400
    enabled:hover:border-green-400
    enabled:focus:outline-color-green-500
    disabled:bg-gray-100 disabled:text-gray-200 disabled:border-gray-200
  }
}
.type-warning {
  &.primary {
    @apply bg-yellow-500
    enabled:active:bg-yellow-800
    enabled:hover:bg-yellow-400
    enabled:focus:outline-color-yellow-500
    disabled:bg-gray-100 disabled:text-gray-200
  }
  &.secondary {
    @apply bg-white text-yellow-500 border-2 border-yellow-500
    enabled:active:text-yellow-800 enabled:active:border-yellow-800
    enabled:hover:text-yellow-400
    enabled:hover:border-yellow-400
    enabled:focus:outline-color-yellow-500
    disabled:bg-gray-100 disabled:text-gray-200 disabled:border-gray-200
  }
}
.type-error {
  &.primary {
    @apply bg-red-500
    enabled:active:bg-red-800
    enabled:hover:bg-red-400
    enabled:focus:outline-color-red-500
    disabled:bg-gray-100 disabled:text-gray-200
  }
  &.secondary {
    @apply bg-white text-red-500 border-2 border-red-500
    enabled:active:text-red-800 enabled:active:border-red-800
    enabled:hover:text-red-400
    enabled:hover:border-red-400
    enabled:focus:outline-color-red-500
    disabled:bg-gray-100 disabled:text-gray-200 disabled:border-gray-200
  }
}
</style>
