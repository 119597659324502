import axiosInstance from '../axios'
import type {CustomerNotification} from '~/types/notification.type'

class CustomerNotificationsModule {
  async getCustomerNotifications(customerId: number) {
    return axiosInstance.get<CustomerNotification[]>(`/customer/${customerId}/notifications`)
  }
}

const module = new CustomerNotificationsModule()
export default module
