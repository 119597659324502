import axiosInstance from '~/api/axios'
import type {Group} from '~/types/diner.type'

export interface DinerGroup {
  diningParticipants: DinerGroupPair[]
}
interface DinerGroupPair {
  diningParticipantId: number
  groups: Group[]
}

class GroupModule {
  fetchGroups(customerId: number) {
    return axiosInstance.get<DinerGroup>(`/customer/${customerId}/groups`)
  }
}

const module = new GroupModule()
export default module
